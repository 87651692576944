export const ru = {
  "auth": {
    "email": "Электронная почта",
    "enterEmail": "Введите почту",
    "enterName": "Введите имя",
    "enterPassword": "Введите пароль",
    "enterSurname": "Введите фамилию",
    "forgotPassword": "Забыли пароль?",
    "name": "Имя",
    "password": "Пароль",
    "signIn": "Вход",
    "signUp": "Регистрация",
    "surname": "Фамилия"
  },
  "bots": {
    "bots": "Боты",
    "bot": "Бот",
    "botsTable": "Таблица ботов",
    "createBot": "Создать бота",
    "deleteBot": "Удалить бота",
    "editBot": "Изменить бота",
    "table": {
      "createdAt": "Создано",
      "id": "ID",
      "identifier": "Идентификатор",
      "name": "Имя",
      "type": "Тип",
      "updatedAt": "Обновлено",
      "username": "Имя пользователя"
    },
    "viewBot": "Просмотреть бота"
  },
  "buttons": {
    "apply": "Применить",
    "cancel": "Отмена",
    "create": "Создать",
    "update": "Обновить",
    "delete": "Удалить",
    "edit": "Изменить",
    "next": "Далее",
    "search": "Поиск",
    "select": "Выбрать"
  },
  "channels": {
    "addNewButton": "Добавить кнопку",
    "buttonText": "Текст кнопки",
    "buttonUrl": "Url кнопки",
    "channels": "Каналы",
    "channelsTable": "Таблица каналов",
    "createChannel": "Создать канал",
    "deleteChannel": "Удалить канал",
    "deletePush": "Удалить пуш",
    "editChannel": "Изменить канал",
    "deleteBotAnswer": "Удалить ответ бота",
    "editBotAnswer": "Изменить ответ бота",
    "enterAfterTime": "Введите After Time",
    "enterMessage": "Введите сообщение",
    "enterText": "Введите текст",
    "enterUrl": "Введите URL",
    "selectButtonType": "Выберите тип кнопки",
    "selectTemplateId": "Выберите ID шаблона",
    "selectType": "Выберите тип",
    "table": {
      "afterTime": "After time",
      "createdAt": "Создано",
      "id": "ID",
      "identifier": "Идентификатор",
      "isActive": "Активный",
      "message": "Сообщение",
      "messageAt": "Дата сообщения",
      "name": "Имя",
      "sourceId": "ID источника",
      "template": "Шаблон",
      "type": "Тип",
      "updatedAt": "Обновлено"
    },
    "updatePush": "Изменить пуш",
    "viewChannel": "Просмотреть канал",
    "push": "Пуш",
    "botAnswer": "Ответ бота",
    "botAnswerIsActive": "Ответ бота активен",
    "botAnswerIsNotActive": "Ответ бота не активен"
  },
  "dtos": {
    "link_many": "Ссылки",
    "link_one": "Ссылка"
  },
  "fields": {
    "channel_many": "Каналы",
    "channel_one": "Канал"
  },
  "footer": {
    "allRightsReserved": "Все права защищены"
  },
  "forms": {
    "botId": "ID Бота",
    "buyer": "Покупатель",
    "channelId": "ID Канала",
    "enterBuyer": "Введите покупателя",
    "enterFbPixel": "Введите fbPixel",
    "enterFbToken": "Введите fbToken",
    "enterSource": "Введите источник",
    "enterName": "Введите имя",
    "enterUsername": "Введите имя пользователя",
    "fbPixel": "fbPixel",
    "fbToken": "fbToken",
    "selectBotId": "Выберите ID бота",
    "selectChannelId": "Выберите ID канала",
    "source": "Источник",
    "sourceId": "ID источника",
    "selectSourceId": "Выберите ID источника",
    "apiId": "API ID",
    "enterApiId": "Введите API ID",
    "apiHash": "API хеш",
    "enterApiHash": "Введите API хеш",
    "phoneNumber": "Номер телефона",
    "enterPhoneNumber": "Введите номер телефона",
    "code": "Код",
    "enterCode": "Введите код",
    "password": "Пароль",
    "enterPassword": "Введите пароль",
    "selectChannels": "Выберите каналы",
    "selectBots": "Выберите ботов",
    "selectSubscribers": "Выберите подписчиков",
    "selectSource": "Выберите источник",
    "enterUrl": "Введите URL"
  },
  "general": {
    "administrator": "Администратор",
    "administrators": "Администраторы",
    "approveTo": "Вы уверены что хотите {{ phrase }}?",
    "createAdministrator": "Создать Администратора",
    "editAdministrator": "Изменение Администратора",
    "filters": "Фильтры",
    "home": "Главная",
    "pageNotFound": "Страница не найдена",
    "personalData": "Персональные данные",
    "table": {
      "dtoTable": "Таблица {{ dtoName }}",
      "noData": "Данные отсутствуют",
      "pagination": "Отображается от {{currCountOfRows}} до {{toCountOfRows}} из {{total}} записей."
    },
    "viewAdministrator": "Просмотр Администратора"
  },
  "links": {
    "contactDeveloper": "Связи с разработчиком",
    "createBotsLink": "Создать ссылку бота",
    "createChannelsLink": "Создать ссылку канала",
    "botsLink": "Ссылка бота",
    "channelsLink": "Ссылка канала",
    "links": "Ссылки",
    "support": "Поддержка",
    "link": "Ссылка",
    "viewLink": "Просмотреть ссылку",
    "table": {
      "deepLink": "Deep",
      "subscribedAt": "Дата подписки",
      "firstName": "Имя",
      "type": "Тип",
      "resource": "Ресурс",
    }
  },
  "profile": {
    "hey": "Привет, {{name}}",
    "logOut": "Выйти"
  },
  "subscribers": {
    "createSubscriber": "Создать подписчика",
    "editSubscriber": "Изменить подписчика",
    "subscribers": "Подписчики",
    "subscribersTable": "Таблица подписчиков",
    "table": {
      "bot": "Бот",
      "buyer": "Покупатель",
      "createdAt": "Создано",
      "id": "ID",
      "identifier": "Идентификатор",
      "isSent": "Отправлено успешно",
      "message": "Сообщение",
      "messages": "Сообщения",
      "name": "Имя",
      "received": "Получено",
      "resource": "Ресурс",
      "resources": "Ресурсы",
      "sendAt": "Отправлено",
      "sent": "Отправлено",
      "source": "Источник",
      "type": "Тип",
      "updatedAt": "Обновлено",
      "username": "Имя пользователя"
    },
    "viewSubscriber": "Просмотреть подписчика"
  },
  "subscriptions": {
    "createSubscription": "Создать подписку",
    "editSubscription": "Изменить подписку",
    "subscriptions": "Подписки",
    "subscriptionsTable": "Таблица подписок",
    "table": {
      "buyer": "Покупатель",
      "createdAt": "Создано",
      "id": "ID",
      "identifier": "Идентификатор",
      "name": "Имя",
      "resource": "Ресурс",
      "source": "Источник",
      "updatedAt": "Обновлено",
      "url": "Url",
      "username": "Имя пользователя"
    },
    "viewSubscription": "Просмотреть подписку"
  },
  "managers": {
    "manager": "Менеджер",
    "editManager": "Изменить менеджера",
    "managers": "Менеджеры",
    "managersTable": "Таблица менеджеров",
    "viewManager": "Просмотреть менеджера",
    "signInQrCode": "Зайти с QR кодом",
    "timeLeft": "Осталось времени: {{timeLeft}} секунд",
    "attemptsLeft": "Попытка: {{attemptsLeft}} / 3",
    "timeExpired": "Время истекло, попробуйте снова позже",
    "signInSendCode": "Зайти с кодом",
    "signInSendCodeAndPassword": "Зайти с кодом и паролем",
    "enterTgCode": "Проверьте ваш Telegram и введите код ниже"
  },
  "validation": {
    "email": "Пожалуйста введите действительный адресс электронной почты",
    "required": "{{field}} обязательное поле",
    "invalidPassword": "Пожалуйста, введите действительный пароль",
    "invalidCode": "Пожалуйста, введите действительный код"
  },
  "yes": "Да",
  "no": "Нет"
};
