import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { typedInfiniteQuery, typedQuery } from "shared/api/types/typedQueries";
import { OneSubscriptionT, SubscriptionsT } from '../types/subscriptions';
import { instance } from "./common";
import { formatFilterParams } from '../../utils/devUtils'

const BASE_URL = 'subscriptions'

const SUBSCRIPTIONS_KEY = 'SUBSCRIPTIONS_KEY';
const ONE_SUBSCRIPTION_KEY = 'ONE_SUBSCRIPTION_KEY';

export const useGetSubscriptions = typedInfiniteQuery<SubscriptionsT>(({ params }) => (
  useInfiniteQuery({
    queryKey: [SUBSCRIPTIONS_KEY, params],
    queryFn: () => instance.get<SubscriptionsT['response']>(BASE_URL, {
      params: {
        ...params,
        filter: formatFilterParams(params.filter),
      }
    }),
    initialPageParam: 1,
    getNextPageParam: () => null,
    getPreviousPageParam: () => null,
  })
));

export const useGetOneSubscription = typedQuery<OneSubscriptionT>(({ pathParams: { subscriptionId } }) => (
  useQuery({
    queryKey: [ONE_SUBSCRIPTION_KEY, subscriptionId],
    queryFn: () => instance.get(`${BASE_URL}/${subscriptionId}`),
    enabled: !!subscriptionId,
  })
));
