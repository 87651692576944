import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetBots } from "shared/api/requests/bots";
import { useGetChannels } from "shared/api/requests/channels";
import { useGetSubscribers } from "shared/api/requests/subscribers";
import CutText from "shared/components/CutText";
import MainItemMenu from "shared/components/MainItemMenu";
import { PathsE } from "shared/routes";
import { FilterField } from "shared/types/filters";
import { formatDateToHuman } from "shared/utils/formattings";
import { SubscriptionsElementT } from '../../api/types/subscriptions';
import { Box, Flex, Text } from '@chakra-ui/react'
import MatIcon from '../../../components/MatIcon'
import useGetColors from '../../hooks/useGetColors'

export const useGetFilterFields = (): FilterField[] => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      type: 'text',
      name: 'name',
      defaultValue: '',
      placeholder: t('forms.enterName'),
      label: t('subscriptions.table.name'),
      size: "lg",
    },
    {
      type: 'text',
      name: 'url',
      defaultValue: '',
      placeholder: t('forms.enterUrl'),
      label: t('subscriptions.table.url'),
      size: "lg",
    },
    {
      type: 'text',
      name: 'source',
      defaultValue: '',
      placeholder: t('forms.enterSource'),
      label: t('subscriptions.table.source'),
      size: "lg",
    },
    {
      type: 'async-select',
      isMulti: true,
      name: 'channelIds',
      defaultValue: [],
      placeholder: t('forms.selectChannels'),
      optionsQuery: useGetChannels,
      label: t('fields.channel', { count: 0 }),
      size: "lg",
      labelName: 'name',
      valueName: 'id',
      fullWidth: true
    },
    {
      type: 'async-select',
      isMulti: true,
      name: 'botIds',
      defaultValue: [],
      placeholder: t('forms.selectBots'),
      optionsQuery: useGetBots,
      label: t('bots.bots'),
      size: "lg",
      labelName: 'name',
      valueName: 'id',
      fullWidth: true
    },
    {
      type: 'async-select',
      isMulti: true,
      name: 'subscriberIds',
      defaultValue: [],
      placeholder: t('forms.selectSubscribers'),
      optionsQuery: useGetSubscribers,
      label: t('subscribers.subscribers'),
      size: "lg",
      labelName: 'name',
      valueName: 'id',
      fullWidth: true
    },
    {
      type: 'date-range',
      name: 'createdAt',
      defaultValue: [],
      label: t('subscribers.table.createdAt'),
      size: "lg"
    },
  ], [t]);
}

export const useGetGetBreadcrumbs = () => {
  const { t } = useTranslation();
  return [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
  ];
}


export const useGetColumns = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<SubscriptionsElementT>();
  const { brand500 } = useGetColors();

  return useMemo(
    () => [
      columnHelper.accessor('id', {
        header: t('subscriptions.table.id'),
        id: 'id',
        cell: ({ getValue }) => (
          <CutText fontWeight="700">
            {getValue()}
          </CutText>
        ),
        meta: {
          isSorting: false,
          styles: {
            sizes: {
              width: '90px',
              maxW: '90px',
            }
          }
        }
      }),
      columnHelper.accessor('identifier', {
        header: t('subscriptions.table.identifier'),
        id: 'identifier',
        meta: {
          isSorting: false,
        }
      }),
      columnHelper.accessor((row) => row.model.name ?? '', {
        header: t('subscriptions.table.resource'),
        id: 'resource',
        cell: ({ getValue }) => (
          <Flex alignItems="center" gap={4}>
            <Box>
              <MatIcon
                name="group"
                color={brand500}
              />
            </Box>
            <Text as="span">{getValue()}</Text>
          </Flex>
        )
      }),
      columnHelper.accessor('username', {
        header: t('subscriptions.table.username'),
        id: 'username',
        meta: {
          isSorting: false,
        }
      }),
      columnHelper.accessor('name', {
        header: t('subscriptions.table.name'),
        id: 'name',
        meta: {
          isSorting: false,
        }
      }),
      columnHelper.accessor('buyer', {
        header: t('subscriptions.table.buyer'),
        id: 'buyer',
        meta: {
          isSorting: false,
        }
      }),
      columnHelper.accessor('source', {
        header: t('subscriptions.table.source'),
        id: 'source',
        meta: {
          isSorting: false,
        }
      }),
      columnHelper.accessor('createdAt', {
        header: t('subscriptions.table.createdAt'),
        id: 'createdAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
      columnHelper.accessor('updatedAt', {
        header: t('subscriptions.table.updatedAt'),
        id: 'updatedAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
      columnHelper.accessor('id', {
        header: '',
        id: 'actions',
        cell: ({ row }) => (
          <MainItemMenu
            data={row.original}
            handleActivate={() => { }}
            handleDeactivate={() => { }}
            // handleActivate={handleActivate}
            // handleDeactivate={handleDeactivate}
            // viewLink={PathsE.SHOW_SUBSCRIPTION}
            pathsParams={{ subscriptionId: row.original.id }}
          />
        ),
        meta: {
          styles: {
            sizes: {
              width: '44px'
            }
          }
        }
      }),
    ] as ColumnDef<SubscriptionsElementT>[],
    [t]
  );
};

